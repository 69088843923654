import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import router from "./router";
const app = createApp(App);

app.use(router);
app.use(ElementPlus);
app.use(ElementPlus, {
  locale: zhCn,
  // 支持 large、default、small
  size: "default",
});
app.mount("#app");
